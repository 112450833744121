import React from 'react';
import Layout from '../../layouts/contact/contact';
import Banner from '../../page-components/contact/banner/banner';

const Contact = () => {
  return (
    <Layout>
      <Banner
        who={'Us'}
        messages={[
          'Optimize customer service chatbot effectiveness',
          'Increase conversions in commerce chatbots',
          'Increase engagement in voice skills',
        ]}
      />
    </Layout>
  );
};

export default Contact;
